import { message, Select } from 'antd'
import { runInAction } from 'mobx'
import moment from 'moment'
import React from 'react'
import { api } from '../api'
import { getDescription, getError } from '../helpers/helpers'
import { vars } from './vars'
import { is } from 'immutable'

export function createStore() {
  return {
    ...vars,
    initialValue: {
      ...vars,
    },
    reset() {
      for (let key in vars) {
        this[key] = this.initialValue[key]
      }
    },
    getResourceTopic(id, onLoad, onError, jurId, firstLoad = false) {
      const url = jurId && id
        ? `/resource_guide/${id}/?jurisdiction=${jurId}`
        : firstLoad
          ? `/resource_guide/${id}/?first_load=true`
          : id ? `/resource_guide/${id}/` : `/resource_guide/`
      api(url, {}, 'GET').then((data) => {
        if (data.errors) {
          onError && onError()
          return
        }
        runInAction(() => {
          this[id ? 'resourcesCurrentTopic' : 'resources'] = data

          if (!id) this.resourcesCurrentTopic = null

          if (id) {
            this.resourceFilteredTopics = data.rows
          } else {
            this.resourceFilteredTopics = null
          }

          onLoad && onLoad()
        })
      })
    },
    getResourceRegisterGuides() {
      const url = '/resources/guides_for_registration/'

      api(url, {}, 'GET').then((data) => {
        if (data.errors) return message.error(getError(data))
        runInAction(() => {
          this.registerGuides = data
        })
      })
    },
    getJurisdiction(id, guideId, onLoad, onError) {
      if (!id) {
        this.resourceFiltered = null
        this.resourcesTable = null

        return
      }

      const url = `/resource_by_jurisdiction/?jurisdiction=${id}${guideId ? `&resource_guide=${guideId}` : ''}`

      api(url, {}, 'GET').then((data) => {
        if (data.errors) {
          onError && onError()
          return
        }
        runInAction(() => {
          this.resourceFiltered = data.results
          this.resourcesTable = data.results
        })
        onLoad && onLoad(data.results)
      })
    },
    getResourceFilters() {
      api('/resource_guide/filters/', {}, 'GET').then((data) => {
        runInAction(() => {
          this.resourceFilters = data
          if (!this.company[0].is_resource_registration_passed)
            this.getCompany()
        })
      })
    },
    setFilteredResources(data) {
      this.resourceFiltered = data
    },
    getCurrentTariff() {
      const filtered = this.tariffInfo?.tariffs?.filter((item) => {
        return item.code === this.company?.[0]?.tariff
      })
      if (filtered?.length) return filtered[0]
      return null
    },
    setCurrentLegislative(legislative, isChange) {
      if (isChange) {
        const item = this.currentLegislative
        this.currentLegislative = { ...item, ...legislative }
      } else {
        this.currentLegislative = legislative
      }
    },
    setSingleValue(variable, value) {
      this[variable] = value
    },
    setToExistingMembers(member) {
      this.members = [...this.members, member]
    },
    setRegisterData(type, value) {
      this.registerData[type] = value
    },
    setAgreement() {
      this.agreement = !this.agreement
    },
    getMember(id) {
      const member = this.members.find((item) => item.id === id)

      return member && `${member.first_name} ${member.last_name}`
    },
    getTariffInfo() {
      api('/payments/tariffs/', {}, 'GET').then((data) => {
        if (!data.errors) {
          runInAction(() => {
            this.tariffInfo = data
            this.currentTariff = this.getCurrentTariff()
          })
        } else {
          message.error(getError(data))
        }
      })
    },
    loadMember(id) {
      api(`/members/${id}/`, {}, 'GET')
        .then((data) => {
          if (!data.errors) {
            runInAction(() => {
              this.member = data
            })
          }
        })
        .catch((err) => console.log(err))
    },
    getSearchableString(obj) {
      let string = ''
      const searchObj = obj

      for (let key in searchObj) {
        if (key === 'date_range') {
          if (obj.today_proximity || obj.date_range) {
            const dateAfter = searchObj[key].split('±')[0]
            const dateBefore = searchObj[key].split('±')[1]
            string += `compliance_date_after=${moment(dateAfter).format(
              'YYYY-MM-DD'
            )}&compliance_date_before=${moment(dateBefore).format(
              'YYYY-MM-DD&'
            )}`
          }
          if (obj.report_due_date) {
            const dateAfter = searchObj[key].split('±')[0]
            const dateBefore = searchObj[key].split('±')[1]
            string += `report_due_date_after=${moment(dateAfter).format(
              'YYYY-MM-DD'
            )}&report_due_date_before=${moment(dateBefore).format(
              'YYYY-MM-DD&'
            )}`
          }
        }
        else if (obj.internal_due_date) {
          const dateAfter = searchObj[key].split('±')[0]
          const dateBefore = searchObj[key].split('±')[1]
          string += `internal_due_date_after=${moment(dateAfter).format(
            'YYYY-MM-DD'
          )}&internal_due_date_before=${moment(dateBefore).format(
            'YYYY-MM-DD'
          )}`
        }
        else if (obj.expiration_date) {
          const dateAfter = searchObj[key].split('±')[0]
          const dateBefore = searchObj[key].split('±')[1]
          string += `expiration_date_after=${moment(dateAfter).format(
            'YYYY-MM-DD'
          )}&expiration_date_before=${moment(dateBefore).format(
            'YYYY-MM-DD'
          )}`
        }
        else if (key === 'date_range1') {
          if (obj.close_date) {
            const dateAfter = searchObj[key].split('±')[0]
            const dateBefore = searchObj[key].split('±')[1]
            string += `close_date_after=${moment(dateAfter).format(
              'YYYY-MM-DD'
            )}&close_date_before=${moment(dateBefore).format(
              'YYYY-MM-DD'
            )}`
          }
        }
        else if (key === 'date_range2') {
          if (obj.task_assigned) {
            const dateAfter = searchObj[key].split('±')[0]
            const dateBefore = searchObj[key].split('±')[1]
            string += `assigned_date_after=${moment(dateAfter).format(
              'YYYY-MM-DD'
            )}&assigned_date_before=${moment(dateBefore).format(
              'YYYY-MM-DD&'
            )}`
          }
        } else if (key === 'date_range3') {
          if (obj.date_added) {
            const dateAfter = searchObj[key].split('±')[0]
            const dateBefore = searchObj[key].split('±')[1]
            string += `date_added_after=${moment(dateAfter).format(
              'YYYY-MM-DD'
            )}&date_added_before=${moment(dateBefore).format(
              'YYYY-MM-DD&'
            )}`
          }
        }
        else if (obj.due_date) {
          const dateAfter = searchObj[key].split('±')[0]
          const dateBefore = searchObj[key].split('±')[1]
          string += `due_date_after=${moment(dateAfter).format(
            'YYYY-MM-DD'
          )}&due_date_before=${moment(dateBefore).format(
            'YYYY-MM-DD&'
          )}`
        }
        else {
          if (searchObj[key]) string += `${key}=${searchObj[key]}&`
        }
      }

      return string
    },
    loadLegislatives(obj, isReportSource, isLicenseSource) {
      const filteredObj = {}

      const notSearchParams = ['setTemporary', 'handleLoading', 'page_size']

      for (let key in obj) {
        if (!notSearchParams.includes(key)) {
          filteredObj[key] = obj[key]
        }
      }

      const getSearchableString = () => {
        let string = ''
        const searchObj = { ...filteredObj, ...this.searchableObject }
        for (let key in searchObj) {
          string += `&${key}=${searchObj[key]}`
        }

        return string
      }


      const status =
        isReportSource
          ? this.legislativesStatus === 'all'
            ? 'all=all'
            : `status=${this.legislativesStatus}`
          : isLicenseSource
            ? this.legislativesStatus === 'actual' ?
              'status=all'
              : this.legislativesStatus === 'calendar' ?
                ''
                : `status=${this.legislativesStatus}`
            : this.legislativesStatus === 'actual'
              ? 'actual=actual'
              : this.legislativesStatus === 'all'
                ? 'all=all'
                : `status=${this.legislativesStatus}`

      this.searchableString = getSearchableString()
      const getData = () => {
        // if (this.legislativesStatus === 'calendar') return
        api(
          `/${isReportSource
            ? 'report_company'
            : isLicenseSource ?
              this.legislativesStatus === 'renewals_reporting'
                || this.legislativesStatus === 'apcd'
                || this.legislativesStatus === 'closed'
                || this.legislativesStatus === 'all_renewals'
                ? 'renewals_reporting' :
                this.legislativesStatus === 'calendar' ?
                  'renewals_reporting_calendar'
                  : this.legislativesStatus === 'assigned'
                    || this.legislativesStatus === 'pending_tasks' ?
                    'license_tasks'
                    : 'license_company'
              : 'legislative'}/?page_size=${obj?.page_size || 20
          }&${status}${getSearchableString()}${isLicenseSource && this.legislativesStatus === 'calendar' ? '&tab_name=calendar' : ''}`,
          {},
          'GET'
        )
          .then((data) => {
            if (!data.errors) {
              runInAction(() => {
                if (obj?.setTemporary) {
                  this.legislativesTemporary = data
                } else {
                  this.legislatives = data
                  this.legislativesArray = data.results
                }
              })
              obj?.handleLoading && obj.handleLoading()
            } else {
              runInAction(() => {
                this.searchableObject = null
              })
              obj.onError && obj.onError()
            }
          })
          .catch((err) => console.log(err))
      }
      getData()
    },

    loadCalendarReports(obj) {
      const filteredObj = {}
      const notSearchParams = ['setTemporary', 'handleLoading', 'page_size']

      for (let key in obj) {
        if (!notSearchParams.includes(key)) {
          filteredObj[key] = obj[key]
        }
      }

      const getSearchableString = () => {
        let string = ''
        const searchObj = { ...filteredObj, ...this.calendarSearchableObject }
        for (let key in searchObj) {
          string += `&${key}=${searchObj[key]}`
        }

        return string
      }

      const status = 'status=calendar'
      this.calendarSearchableString = getSearchableString()
      const getData = () => {
        api(
          `/report_company/?page_size=${obj?.page_size || 20
          }&${status}${getSearchableString()}`,
          {},
          'GET'
        )
          .then((data) => {
            if (!data.errors) {
              runInAction(() => {
                if (obj?.setTemporary) {
                  this.calendarReportsTemporary = data
                } else {
                  this.calendarReports = data
                }
              })
              obj?.handleLoading && obj.handleLoading()
            } else {
              runInAction(() => {
                this.searchableObject = null
              })
              obj.onError && obj.onError()
            }
          })
          .catch((err) => console.log(err))
      }
      getData()
    },
    loadReports(obj) {
      const filteredObj = {}

      const notSearchParams = ['setTemporary', 'handleLoading', 'page_size']

      for (let key in obj) {
        if (!notSearchParams.includes(key)) {
          filteredObj[key] = obj[key]
        }
      }

      const getSearchableString = () => {
        let string = ''
        const searchObj = { ...filteredObj, ...this.searchableObject }
        for (let key in searchObj) {
          string += `&${key}=${searchObj[key]}`
        }

        return string
      }

      const status =
        this.reportsStatus === 'actual'
          ? 'actual=actual'
          : `status=${this.reportsStatus}`

      const getData = () =>
        api(
          `/report/?page_size=${obj?.page_size || 20
          }&${status}${getSearchableString()}`,
          {},
          'GET'
        )
          .then((data) => {
            if (!data.errors) {
              runInAction(() => {
                if (obj?.setTemporary) {
                  this.reportsTemporary = data
                } else {
                  this.reports = data
                  this.reportsArray = data.results
                }
              })
              obj?.handleLoading && obj.handleLoading()
            } else {
              runInAction(() => {
                this.searchableObject = null
              })
              obj.onError && obj.onError()
            }
          })
          .catch((err) => console.log(err))

      getData()
    },
    loadTasks(obj, isReportSource) {
      const filteredObj = {}

      const notSearchParams = ['setTemporary', 'handleLoading', 'page_size']

      for (let key in obj) {
        if (!notSearchParams.includes(key)) {
          filteredObj[key] = obj[key]
        }
      }

      const getSearchableString = () => {
        let string = ''
        const searchObj = { ...filteredObj, ...this.searchableObjectTasks }
        for (let key in searchObj) {
          string += `&${key}=${searchObj[key]}`
        }

        return string
      }

      this.searchableString = getSearchableString()

      const url = isReportSource ?
        `/report_company/my_tasks/?status=mytasks&page_size=${obj?.page_size || 20}${getSearchableString()}`
        : `/tasks/my_tasks/?page_size=${obj?.page_size || 20}${getSearchableString()}`
      api(
        url,
        {},
        'GET'
      ).then((data) => {
        if (!data.errors) {
          runInAction(() => {
            if (obj.setTemporary) {
              this.tasksTemporary = data
            } else {
              this.tasks = data
            }
          })
          obj?.handleLoading && obj?.handleLoading()
        }
      })
    },

    mutateLegislatives(id, data) {
      const index = this.legislatives.results.findIndex(
        (item) => item.id === id
      )

      const item = this.legislatives.results[index]

      let legislatives = this.legislatives.results

      legislatives[index] = { ...item, ...data }

      runInAction(() => {
        this.legislatives.results = legislatives
      })
    },
    setAddedMember(member, onAdded, onError, isResource) {
      const checkForRepeat = this.addedMembers.filter(
        (item) => item.email === member.email
      )

      const checkForRepeatExisting = this.members.filter(
        (item) => item.email === member.email
      )

      const isError = isResource
        ? checkForRepeat.length
        : checkForRepeat.length || checkForRepeatExisting.length

      if (isError) {
        onError && onError()
        return
      }

      this.addedMembers.unshift(member)

      onAdded && onAdded()
    },
    getAddedMember(id, existing) {
      return this[existing ? 'members' : 'addedMembers'].find(
        (item) => item.id === id
      )
    },
    modifyAddedMember(member, existing) {
      const index = this[existing ? 'members' : 'addedMembers'].findIndex(
        (item) => item.id === member.id
      )

      this[existing ? 'members' : 'addedMembers'][index] = member
    },
    deleteAddedMember(id, existing) {
      this[existing ? 'members' : 'addedMembers'] = this[
        existing ? 'members' : 'addedMembers'
      ].filter((item) => item.id !== id)
    },
    mergeMembers() {
      this.members = [...this.members, ...this.addedMembers]
      this.addedMembers = []
    },
    setComment(comment, reply, parentId) {
      if (reply) {
        this.comments.results
          .find((item) => item.id === parentId)
          .descendants.unshift(comment)
      } else {
        this.comments.results = [comment, ...this.comments.results]
      }
    },
    setDocument(document) {
      this.documents.results = [document, ...this.documents.results]
    },
    updateCompany(item) {
      const company = this.company[0]

      this.company[0] = { ...company, ...item }
    },
    getCompany(onLoaded) {
      api('/companies/', {}, 'GET').then((data) => {
        if (!data.errors) {
          onLoaded && onLoaded()
          this.company = data
        }
      })
    },
    getUser(onLoaded, member) {
      const url = member ? `/members/${this.company[0].owner}/` : `/users/`

      api(url, {}, 'GET').then((data) => {
        if (!data.errors) {
          this[member ? 'member' : 'user'] = data

          onLoaded && onLoaded()
        }
      })
    },
    loadSelect(type, onLoad, onlyPaidGuides = true) {
      const getVarToSet = (value, type) => {
        const varTypes = {
          jurisdictions:
            type === 'companyOptions'
              ? 'jurs'
              : type === 'memberOptions'
                ? 'jursMembers'
                : 'jursCurrentMember',
          topics:
            type === 'companyOptions'
              ? 'topics'
              : type === 'memberOptions'
                ? 'topicsMembers'
                : 'topicsCurrentMember',
          line_of_business:
            type === 'companyOptions'
              ? 'lineOfBusinesses'
              : type === 'memberOptions'
                ? 'lineOfBusinessesMembers'
                : 'lineOfBusinessesCurrentMember',
          result: type,
          resources_guides:
            type === 'companyOptions'
              ? 'resourceGuides'
              : type === 'memberOptions'
                ? 'resourceMembers'
                : 'resourceCurrentMember',
          resource_jurisdictions:
            type === 'companyOptions'
              ? 'resourceJurisdictions'
              : type === 'memberOptions'
                ? 'resourceJurisdictionsMembers'
                : 'resourceJurisdictionsCurrentMember',
          report_source_ties_to_license:
            type === 'companyOptions'
              ? 'reportTiesToLicense' : 'reportTiesToLicenseCurrentMember'
        }

        return varTypes[value] ?? null
      }

      const setValue = (data, type) => {
        if (typeof data === 'object') {
          for (let key in data) {
            if (type === 'states' ? data.result : data[key].length) {
              const options = data[type === 'states' ? 'result' : key].map(
                (item) => item
              )

              runInAction(() => {
                this[getVarToSet(key, type) || key] = options
              })
            }
          }
        }
      }

      const selectType = {
        companyOptions: '/companies/company_options/',
        memberOptions: `/companies/member_options/?only_paid_guides=${onlyPaidGuides}`,
        currentMemberOptions: '/companies/current_member_options/',
        states: '/companies/states_options/',
      }

      api(selectType[type], {}, 'GET')
        .then((data) => {
          if (!data.errors) {
            setValue(data, type)
            onLoad && onLoad()
          } else {
            this[type] = []
          }
        })
        .catch((err) => console.log(err))
    },
    loadMembers(obj, isReportSource = false, isLicenseSource = false) {
      const getSearchableString = () => {
        let string = ''

        const searchObj = { ...this.searchableMembersObject }

        for (let key in searchObj) {
          string += `${key}=${searchObj[key]}&`
        }

        return string
      }

      const selectOption = (item, type) => (
        <Select.Option
          key={item.id}
          value={type === 'mention' ? item.username : item.id.toString()}
        >
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )

      api(`/members/?${getSearchableString()}`, {}, 'GET').then((data) => {
        if (!data.errors) {
          const mentionMembers = data
            .filter((item) => isReportSource
              ? item.have_access_to_report_source
              : isLicenseSource
                ? item.have_access_to_license_source
                : item.have_access_to_tracking)
            .map((item) => selectOption(item, 'mention'))

          runInAction(() => {
            this.members = data
            if (!obj) {
              this.membersMentions = mentionMembers
            }
          })
        }
      })
    },
    loadComments(legislative, page, handleLoading, isReportSource, isLicenseSource = false, isRenewalsReporting = false, reportId, isNeedHistory = false) {
      api(
        `${isReportSource
          ? `/report_company/${legislative}/comments/?&page=${page}&page_size=5`
          : isLicenseSource ?
            isRenewalsReporting ?
              `/renewals_reporting/${legislative}/comments/?&page=${page}&page_size=5`
              : isNeedHistory ?
                `/license_company/${legislative}/comments/?&page=${page}&for_history=true&page_size=5`
                : `/license_company/${legislative}/comments/?&page=${page}&page_size=5`
            : `/legislative_comments/?legislative=${legislative}&page=${page}&page_size=5`}`,
        {},
        'GET'
      ).then((data) => {
        if (!data.errors) {
          runInAction(() => {
            this.comments = data
          })
          handleLoading && handleLoading()
        }
      })
    },
    loadDocuments(legislative, page, status = 'working', isReportSource = false, isLicenseSource = false, isRenewalsReporting = false, isNeedHistory = false) {
      api(isReportSource ?
        `/report_company/${legislative}/files/?status=${status}` :
        isLicenseSource ?
          isRenewalsReporting ?
            `/renewals_reporting/${legislative}/files/?status=${status}`
            : isNeedHistory
              ? `/license_company/${legislative}/files/?status=${status}&for_history=true`
              : `/license_company/${legislative}/files/?status=${status}`
          : `/legislative_file/?legislative=${legislative}&page=${page}`,
        {},
        'GET'
      ).then((data) => {
        if (!data.errors) {
          this.documents = data
        }
      })
    },
    deleteDocument(id, onDeleted) {
      api(`/legislative_file/${id}/`, {}, 'DELETE').then((data) => {
        if (!data.errors) {
          this.documents.results = this.documents.results?.filter(
            (item) => item.id !== id
          )
          onDeleted && onDeleted()
        }
      })
    },
    deleteReportDocument(companyId, id, onDeleted) {
      api(`/report_company/${companyId}/files/${id}/`, {}, 'DELETE').then((data) => {
        if (!data.errors) {
          this.documents.results = this.documents.results?.filter(
            (item) => item.id !== id
          )
          onDeleted && onDeleted()
        }
      })
    },
    deleteLicenseDocument(companyId, id, onDeleted, isRenewalsReporting) {
      const url = isRenewalsReporting ?
        `/renewals_reporting/${companyId}/files/${id}/`
        : `/license_company/${companyId}/files/${id}/`
      api(url, {}, 'DELETE').then((data) => {
        if (!data.errors) {
          this.documents.results = this.documents.results?.filter(
            (item) => item.id !== id
          )
          onDeleted && onDeleted()
        }
      })
    },
    loadActivity(legislative, page, handleLoading, isReportSource = false, isLicenseSource = false, isRenewalsReporting = false, isNeedHistory = false, isAssessmentHistory = false) {
      const url = isReportSource
        ? `/report_company/${legislative}/activities/?page=${page}`
        : isLicenseSource ?
          isRenewalsReporting ?
            `/renewals_reporting/${legislative}/activity/?page=${page}`
            : isNeedHistory
              ? `/license_company/${legislative}/activity/?page=${page}&for_history=true`
              : isAssessmentHistory ?
                `/license_company/${legislative}/activity/?page=${page}&for_assessment_history=true`
                : `/license_company/${legislative}/activity/?page=${page}`
          : `/legislative_activity/?legislative=${legislative}&page=${page}`
      api(url,
        {},
        'GET'
      ).then((data) => {
        if (!data.errors) {
          const history = isNeedHistory || isAssessmentHistory
          history ? this.historyActivity = data : this.activity = data
          handleLoading && handleLoading()
        }
      })
    },
    getBillingCards() {
      api('/payment_cards/', {}, 'GET').then((data) => {
        if (!data.errors) {
          runInAction(() => {
            this.billingCards = data.results
          })
        }
      })
    },
    getResourceTariffInfo(onLoad) {
      api('/resources/my-tariff/', {}, 'GET').then((data) => {
        if (!data.errors) {
          runInAction(() => {
            this.resourceTariffInfo = data
          })
          onLoad && onLoad()
        } else {
          message.error('Error while getting info about resource tariff')
        }
      })
    },
    getBills() {
      api('/proposed_source/', {}, 'GET').then((data) => {
        if (!data.errors) {
          runInAction(() => {
            this.bills = data.results
          })
        }
      })
    },
    getSelectedJurisdictions() {
      api('/resources/selected_jurisdictions/', {}, 'GET').then((data) => {
        if (data.errors) return message.error(getError(data))

        this.selectedJurisdictions = data.jurisdictions.map((item) => ({
          id: item,
          name: getDescription(this.jurs, item),
        }))
      })
    },

    setResourcePrevLink(arr) {
      this.resourcePrevLink = arr
    },

    setPrimaryCard(id) {
      api(`/payment_cards/${id}/set_is_primary/`, {}, 'POST').then((data) => {
        if (data.errors) return message.error(getError(data))

        message.success('Card set as primary')
      })
    },

    getLegislativeOptions() {
      api('/companies/legislative_options/', {}, 'GET').then((data) => {
        if (data.errors) return message.error(data)

        this.legislative_options = data
      })
    },
  }
}
