import React, { useState, useEffect } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Row, SubTitle } from './style'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import { Controller } from 'react-hook-form'
import CalendarCustom from '../../../../components/common/Calendar'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import deleteFileIcon from '../../../../assets/images/delete-orange.svg'
import { useGA4React } from 'ga-4-react'
import moment from 'moment'
import { message } from 'antd'

const HeldModal = ({
  editMode,
  data,
  onClose,
  id,
  currentPage,
  isPerpetual,
  filingTimePeriod,
  mandatedExpirationDate
}) => {
  const store = useStore()
  const { control, errors, setValue, handleSubmit, formState, trigger, watch, reset } = useForm({
    mode: 'onChange',
  })
  const [temporaryFiles, setTemporaryFiles] = useState([])
  const ga4React = useGA4React()

  const postFile = (files) => {

    ga4React?.gtag('event', 'file_upload')

    let formData = new FormData()

    formData.append('file', files[0])

    api(`/license_company/${id}/certificates/`, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
        message.success('File uploaded successfully')
      } else {
        message.error('File upload failed')
      }
    })
  }

  const addHeldDocs = (temporaryFiles) => {
    temporaryFiles.length > 0 &&
      temporaryFiles.map((item) => {
        return postFile(item)
      })
  }
  const onSubmit = (values) => {

    const url = editMode
      ? `/license_company/${id}/change_held_information/`
      : `/license_company/${id}/move_license_to_held/`
    const obj = isPerpetual
      ? {
        status: 'held',
        date_license_obtained: moment(values.date_license_obtained).format('YYYY-MM-DD'),
        license_number: values.license_number,
      } : {
        status: 'held',
        date_license_obtained: moment(values.date_license_obtained).format('YYYY-MM-DD'),
        renewal_date: moment(values.renewal_date).format('YYYY-MM-DD'),
        expiration_date: values.expiration_date ? moment(values.expiration_date).format('YYYY-MM-DD') : null,
        license_number: values.license_number,
      }
    api(url, obj, editMode ? 'PATCH' : 'POST').then((data) => {
      if (!data.errors) {
        addHeldDocs(temporaryFiles)
        store.setCurrentLegislative(data, true)
        store.loadLegislatives({ page: currentPage }, false, true)
        onClose()
      }
    })
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files]])
  }

  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item[0].name !== name)
    setTemporaryFiles(filtered)
  }
  useEffect(() => {
    trigger();
  }, [watch('date_license_obtained'), watch('renewal_date'), watch('expiration_date')]);

  useEffect(() => {
    editMode && reset(data)
  }, [])

  return (
    <ModalWrap onClose={onClose}>
      <Label>{editMode ? 'Edit License Info' : 'License Held Information'}</Label>
      <SubTitle>Filing Time Period: {filingTimePeriod}</SubTitle>
      {!isPerpetual && <SubTitle>State Mandated Expiration Date:{mandatedExpirationDate} </SubTitle>}
      <Row>
        <Controller
          control={control}
          name={'date_license_obtained'}
          rules={{ required: !editMode }}
          render={({ onChange, value }) => (
            <CalendarCustom
              handleChange={value =>
                setValue('date_license_obtained', value)
              }
              placeholder={'Select Date'}
              noLimit={true}
              incomeValue={value}
              required={!editMode}
              label={'License Effective Date'}
              error={errors?.date_license_obtained && 'This field is required'}
            />
          )}
        />
        <Controller
          control={control}
          name={'renewal_date'}
          rules={{ required: !editMode && !isPerpetual }}
          render={({ onChange, value }) => (
            <CalendarCustom
              handleChange={(value) => {
                !isPerpetual && setValue('renewal_date', value)
              }}
              disabled={isPerpetual}
              required={!isPerpetual && !editMode}
              placeholder={isPerpetual ? 'Perpetual' : 'Select Date'}
              incomeValue={value}
              noLimit={true}
              label={'Filing Due Date'}
              error={errors?.renewal_date && 'This field is required'}
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          name={'expiration_date'}
          rules={{ required: !isPerpetual && !editMode }}
          render={({ onChange, value }) => (
            <CalendarCustom
              handleChange={(value) => {
                !isPerpetual && setValue('expiration_date', value)
              }}
              noLimit={true}
              placeholder={isPerpetual ? 'Perpetual' : 'Select Date'}
              incomeValue={value}
              disabled={isPerpetual}
              required={!isPerpetual && !editMode}
              label={'State Mandated Expiration Date'}
              error={errors?.expiration_date && 'This field is required'}
            />
          )}
        />
        <Input
          style={{ marginBottom: 0 }}
          smallLabel
          name={'license_number'}
          label={'License Number'}
          defaultValue={editMode ? data?.license_number : ''}
          type={'text'}
          control={control}
          validation={{ required: true }}
          disableValidation={editMode}
          transparent
          placeholder={'Enter Number'}
          error={errors?.license_number && 'This field is required'}
        />
      </Row>
      {!editMode &&
        <>
          <Input
            type={'file'}
            name={'file'}
            label={'Attach License Certificate(s):'}
            control={control}
            styleRow={{ marginTop: 10, marginBottom: 10 }}
            handleChange={(e) => addFile(e)}
            multiple
            smallLabel
          />
          {temporaryFiles.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonOrange text={item[0].name} key={index} />
              <img
                src={deleteFileIcon}
                alt={'delete-icon'}
                onClick={() => deleteFile(item[0].name)}
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </div>
          ))}
        </>
      }

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          text={editMode ? 'Edit License Info' : 'Change to License Held'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        />
      </div>
    </ModalWrap>
  )
}

export default HeldModal
