import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import closeIcon from '../../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../../mobx-store/context'
import { Text } from '../../../right.style'
import { getFiles } from '../index'
import { Overlay, Title, Wrap, Subtitle } from '../style'
import { downloadDoc } from '../../../../../../api'

const ModalCompleted = ({ onClose, data, tasks, taskID, getTasks, isReportSource, isLicenseSource }) => {
  const store = useStore()
  const [files, setFiles] = useState([])

  const deleteFile = (id) => {
    isReportSource
      ? store.deleteReportDocument(data.report_company, id, () => getTasks())
      : store.deleteDocument(id, () => getTasks())
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setFiles(getFiles(tasks, taskID))
    }
  }, [tasks])


  return (
    <div>
      <Overlay />
      <Wrap>
        <img src={closeIcon} alt='close-icon' onClick={onClose} id='close' />
        <Title style={{
          marginBottom: 0,
        }}>
          {isReportSource || isLicenseSource
            ? data.type === 'get_ready_task'
              ? 'Completed Check List Task'
              : 'Completed Task'
            : data.type !== 'default'
              ? 'Completed Considerations Task'
              : 'Completed Task'}
        </Title>
        <Subtitle>
          <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
        </Subtitle>
        <Subtitle>
          <strong>Task: </strong>{data?.description}
        </Subtitle>
        <Title style={{
          fontSize: 16,
          marginTop: 25,
          marginBottom: 0,
        }}>
          Task Completed By:
        </Title>
        <Title
          style={{
            fontSize: 14,
            marginBottom: 0,
          }}
        >
          {store.getMember(data?.completed_by)}
        </Title>
        <span>
          <Moment format={'lll'}>{isReportSource ? data?.modified : data?.comments?.[0]?.created}</Moment>
        </span>
        <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
          Task Comment*
        </Title>
        <Text style={{ margin: 0 }}>{isReportSource|| isLicenseSource ? data?.comment : data?.comments?.[0]?.text}</Text>

        {files?.length && (
          <>
            <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
              Uploaded Document
            </Title>
            {files.map((item) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonOrange text={item.name}
                  onClick={() => downloadDoc(item.id, item.name, isReportSource, isLicenseSource, false, store?.currentLegislative?.id, store?.currentLegislative?.report?.id)} />
                <img
                  src={deleteFileIcon}
                  alt={'delete-icon'}
                  onClick={() => deleteFile(item.id,)}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>
            ))}
          </>
        )}

        <Button
          text={'Close'}
          style={{ marginLeft: 'auto', marginTop: 20 }}
          onClick={onClose}
        />
      </Wrap>
    </div>
  )
}

export default ModalCompleted
