import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import useModal from '../../../../hooks/useModal'
import { useStore } from '../../../../mobx-store/context'
import { Label } from '../right.style'
import List from './List'
import Modal from './Modal'
import { Placeholder, Wrap } from './style'

const Tasks = observer(
  ({
    data,
    getCurrentLegislative,
    postFile,
    isMyTasks,
    currentPage,
    loadingFile,
    complianceItem,
    getTasks,
    tasks,
    activeTab,
    setActiveTab,
    isReportSource = false,
    isLicenseSource = false,
    initialApplication = false,
    visible,
    handleClose,
    handleOpen,
    assigneNew,
    setAssigneNew,
    getConsiderationData,
    receiveGetReadyData,
    disabled = false,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors, reset, getValues } = useForm()
    const [shouldComplete, setShouldComplete] = useState(false)
    const [temporaryFiles, setTemporaryFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentTask, setCurrentTask] = useState()

    const params = useParams()
    const history = useHistory()

    const ID = data.id
    const reportId = data?.report?.id

    const assignee = data.status === 'needed' ? data?.initial_application_assignee : data?.assignee

    let search = new URL(document.location).searchParams
    let taskID = search.get('taskID')
    const isRenewalsReporting = params.tabName === 'renewals_reporting' || params.tabName === 'assigned' || params.tabName === 'all_renewals'
    const isInitalApplication = params.tabName === 'ia_all' || params.tabName === 'ia_unassigned' || params.tabName === 'ia_in_progress' || params.tabName === 'ia_in_progress_ready_for_final_review' || params.tabName === 'ia_in_progress_submitted_to_govt' || params.tabName === 'ia_in_progress_deficiency'

    const onComplete = (values) => {
      api(isReportSource ?
        `/report_company/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
        : isLicenseSource ?
          isRenewalsReporting ?
            `/renewals_reporting/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
            : `/license_company/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
          : `/tasks/${taskID || store.currentLegislative.id}/complete/`,
        { text: values.description },
        'POST'
      ).then((data) => {
        setLoading(false)

        if (!data.errors) {
          temporaryFiles.length > 0 &&
            temporaryFiles.map((item) => {
              postFile(null, taskID, item, true)
            })
          setTemporaryFiles([])
          getCurrentLegislative(ID, null, null, true, isReportSource, isLicenseSource)
          isReportSource || isLicenseSource ? receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id) : getConsiderationData()
          getTasks()
          store.loadActivity(store.currentLegislative.id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting)
          handleClose()
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, isReportSource && localStorage.getItem('reportInLs') !== 'true', isLicenseSource || localStorage.getItem('reportInLs') === 'true')
          const routePath = isReportSource && localStorage.getItem('reportInLs') !== 'true'
            ? '/report-source'
            : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              ? '/license-source'
              : '/tracking';

          const taskRoutes = {
            closed: 'closed',
            all: 'all',
            myTasks: 'myTasks',
            main: 'main',
            apcd: 'apcd',
            monitor: 'monitor',
            held: 'held',
            not_assessed: 'not_assessed',
            need: 'need',
            na: 'na',
            renewals_reporting: 'renewals_reporting',
            pending_tasks: 'pending_tasks',
            assigned: 'assigned',
            all_renewals: 'all_renewals',
            ia_all: 'ia_all',
            ia_unassigned: 'ia_unassigned',
            ia_in_progress: 'ia_in_progress',
            ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
            ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
            ia_in_progress_deficiency: 'ia_in_progress_deficiency',
          };

          const routeType = isMyTasks
            ? taskRoutes[params.tabName] || 'myTasks'
            : taskRoutes[params.tabName] || 'closed';

          const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true'
            ? 'report'
            : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              ? 'license_item'
              : 'compliance_item';
          const complianceItemId = complianceItem || ID;
          const route = `${routePath}/${routeType}/?${queryParam}=${complianceItemId} `;
          history.push(route);
        }
        setActiveTab()
        setShouldComplete(false)
      })
    }

    const onSubmit = (values) => {
      setLoading(true)
      if (shouldComplete) return onComplete(values)

      const getBasePath = () => {
        if (isReportSource) return `/report_company/${store.currentLegislative.id}/tasks/`;

        if (isLicenseSource) {
          if (['renewals_reporting', 'assigned', 'all_renewals'].includes(activeTab)) {
            return `/renewals_reporting/${store.currentLegislative.id}/tasks/`;
          }
          return `/license_company/${store.currentLegislative.id}/tasks/`;
        }

        return '/tasks/';
      };

      const url = taskID && !assigneNew
        ? `${getBasePath()}${taskID}/`
        : getBasePath();

      const isInitialApplication = [
        'ia_all',
        'ia_unassigned',
        'ia_in_progress',
        'ia_in_progress_ready_for_final_review',
        'ia_in_progress_submitted_to_govt',
        'ia_in_progress_deficiency',
      ].includes(params.tabName);

      const getObjectType = () => {
        if (isReportSource || isLicenseSource) {
          return store.getReadyTask?.id ? 'get_ready_task' : 'default';
        }
        return store.considerationTask || 'default';
      };

      const object = taskID && !assigneNew
        ? {
          legislative: store.currentLegislative.id,
          ...values,
        }
        : {
          legislative: store.currentLegislative.id,
          type: getObjectType(),
          ...(isInitialApplication && { initial_application_status: params.tabName }),
          ...(store.getReadyTask?.id && { get_ready_checkbox: store.getReadyTask.id }),
          ...values,
        };

      api(url, object, taskID && !assigneNew ? 'PATCH' : 'POST').then((data) => {
        setLoading(false)
        if (isReportSource || isLicenseSource) {
          receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id);
          store.setSingleValue('getReadyTask', null);
        } else {
          getConsiderationData();
          store.setSingleValue('considerationTask', null);
        }
        if (!data.errors) {
          handleClose()
          getCurrentLegislative(ID, null, null, true, isReportSource, isLicenseSource)
          store.loadActivity(ID, 1, null, isReportSource, isLicenseSource, isRenewalsReporting)
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, (isReportSource && localStorage.getItem('reportInLs') !== 'true'), (isLicenseSource || localStorage.getItem('reportInLs') === 'true'))
          getTasks()
          const routePath = isReportSource && localStorage.getItem('reportInLs') !== 'true'
            ? '/report-source/'
            : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              ? '/license-source/'
              : '/tracking/';

          const taskRoutes = {
            closed: 'closed',
            all: 'all',
            main: 'main',
            apcd: 'apcd',
            monitor: 'monitor',
            need: 'need',
            held: 'held',
            na: 'na',
            not_assessed: 'not_assessed',
            renewals_reporting: 'renewals_reporting',
            pending_tasks: 'pending_tasks',
            assigned: 'assigned',
            all_renewals: 'all_renewals',
            ia_all: 'ia_all',
            ia_unassigned: 'ia_unassigned',
            ia_in_progress: 'ia_in_progress',
            ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
            ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
            ia_in_progress_deficiency: 'ia_in_progress_deficiency',
          };

          const routeType = isMyTasks
            ? taskRoutes[params.tabName] || 'myTasks'
            : taskRoutes[params.tabName] || 'all';

          const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true' ? 'report' : isLicenseSource || localStorage.getItem('reportInLs') === 'true' ? 'license_item' : 'compliance_item';
          const complianceItemId = ID || params.id;
          const route = `${routePath}${routeType}/?${queryParam}=${complianceItemId}`;
          history.push(route)
          setAssigneNew(false)
        }
      })
    }
    const getMessage = () => {
      if (disabled) {
        return '';
      }
      if (isReportSource) {
        return isRenewalsReporting
          ? 'You must assign a Report Item Owner before assigning tasks.'
          : 'You must assign a ReportSource Owner before assigning tasks.';
      }

      if (isLicenseSource) {
        const isPendingOrAssigned = params.tabName === 'pending_tasks' || params.tabName === 'assigned';
        const isNotAssessedOrNaOrMonitor = ['not_assessed', 'na', 'monitor'].includes(store?.currentLegislative?.status);

        if (!isRenewalsReporting && !isPendingOrAssigned && isNotAssessedOrNaOrMonitor) {
          return 'You must assign an Assessment Item Owner before assigning tasks.';
        }

        if (store?.currentLegislative?.status === 'needed' && !isPendingOrAssigned && !disabled) {
          return 'You must assign a License Item Owner before assigning tasks.';
        }

        if (isRenewalsReporting || isPendingOrAssigned) {
          return 'You must assign a Renewal Item Owner before assigning tasks.';
        }
      }

      return 'You must assign a ComplianceSource Owner before assigning tasks.';
    };

    const getLabel = () => {
      const isAssessmentStatus = ['not_assessed', 'na', 'monitor'].includes(store?.currentLegislative?.status);

      if (isLicenseSource && isAssessmentStatus && !disabled) {
        return 'Assessment Tasks';
      }

      return 'Tasks';
    };

    const addFiles = (files) => {
      setTemporaryFiles(files)
      reset({ ...getValues(), file: null })
    }

    useEffect(() => {
      getTasks()
    }, [store.currentLegislative])

    useDidMountEffect(() => {
      if (!visible) {
        reset({ file: null })
      }
    }, [visible])

    return (
      <Wrap name={'tasks-block'}>
        <Label>{getLabel()}</Label>
        {visible && (
          <Modal
            data={currentTask}
            control={control}
            onClose={() => {
              handleClose()
              reset({})
              setShouldComplete(false)
              setAssigneNew(false)
              setTemporaryFiles([])
              setCurrentTask(null)
              store.setSingleValue('getReadyTask', null)
              store.setSingleValue('considerationTask', null)
            }}
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            assigneNew={assigneNew}
            complete={shouldComplete}
            postFile={postFile}
            taskID={taskID}
            tasks={tasks}
            complianceItemId={ID || params.id}
            complianceItem={complianceItem}
            loadingFile={loadingFile}
            redCircleDate={data?.compliance_date}
            temporaryFiles={temporaryFiles}
            setTemporaryFiles={addFiles}
            loading={loading}
            getTasks={getTasks}
            activeTab={activeTab}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
          />
        )}
        {
          disabled && tasks.length === 0 ? <Placeholder>There is no tasks history for this item.</Placeholder>
            : !data.assignee && (
              <Placeholder>{getMessage()}</Placeholder>
            )}
        {tasks.length > 0 && (
          <List
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            tasks={tasks}
            complianceItem={complianceItem || ID || params.id}
            isMyTasks={isMyTasks}
            taskID={taskID}
            onModalOpen={(data) => {
              reset(data)
              handleOpen()
            }}
            onComplete={() => {
              setShouldComplete(true)
              setAssigneNew(false)
              handleOpen()
            }}
            data={data}
            getTasks={getTasks}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            activeTab={activeTab}
            disabled={disabled ? disabled : store?.currentLegislative?.status === 'held'}
          />
        )}
        {isReportSource
          ? assignee &&
          checkRole(
            [store.user?.[0]?.report_source_role],
            ['owner', 'admin', 'report_owner']
          ) &&
          data.status !== 'closed' && (
            <ButtonOrange
              text={'Assign a Task'}
              onClick={() => {
                reset({
                  assignee: null,
                  due_date: null,
                  description: null,
                })
                setAssigneNew(true)
                handleOpen()
              }}
            />
          )
          :
          isLicenseSource ?
            assignee &&
            checkRole(
              [store.user?.[0]?.license_source_role],
              ['owner', 'admin', 'license_owner']
            ) &&
            (data.status !== 'held') && !disabled && (
              <ButtonOrange
                text={'Assign a Task'}
                onClick={() => {
                  reset({
                    assignee: null,
                    due_date: null,
                    description: null,
                  })
                  setAssigneNew(true)
                  handleOpen()
                }}
              />
            )
            : assignee &&
            checkRole(
              [store.user?.[0]?.member_type],
              ['owner', 'admin', 'compliance_owner']
            ) &&
            data.status !== 'closed' && (
              <ButtonOrange
                text={'Assign a Task'}
                onClick={() => {
                  reset({
                    assignee: null,
                    due_date: null,
                    description: null,
                  })
                  setAssigneNew(true)
                  handleOpen()
                }}
              />
            )}
      </Wrap>
    )
  }
)

export default Tasks
