import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import { getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { columnsCommon, columnsCommonLite, columnsReportsReporting, ultraLiteCommon } from '../Table/columns'
import { Header, HeaderTitle, Render, TableStyled } from '../Table/table.style'
import ExpandItem from './expandItem'
import { Button as StyledButton, ExportText, Grid, ReportingContainer } from './style'
import TopLine from './topLine'
import ItemsShowing from '../../../components/common/TableItemsShowing'
import { getStatusColor } from '../../../helpers/helpers'
import { getRecord } from '../Table/columns'
import { Status } from '../Table/table.style'
import { Observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { columnsCoreTasksReporting } from '../Table/columns'
import {
  getDate,
} from '../../../helpers/helpers'

const pageSize = 20

const Reporting = ({ isReportSource = false, reportingTask = false }) => {
  const store = useStore()
  const [data, setData] = useState([])
  const [searchString, setSearchString] = useState(null)
  const [sorter, setSorter] = useState(null)
  const [page, setPage] = useState(1)
  const [totals, setTotals] = useState(null)
  const [staticTotals, setStaticTotals] = useState(null)
  const [active, setActive] = useState(null)
  const [expanded, setExpanded] = useState([])
  const [loading, setLoading] = useState(false)
  // const [pageCount, setPageCount] = useState(0)

  const trackingMode = isReportSource ? store?.company[0]?.report_source_mode : store?.company[0]?.compliance_source_mode

  const getData = () => {
    if (isReportSource) {
      setLoading(true)
      const url = reportingTask
        ? `/report_source/tasks/reporting/?page_size=${pageSize}&page=${page}`
        : `/report_source/reporting/?page_size=${pageSize}&page=${page}`
      api(
        url +
        (searchString
          ? '&' + store.getSearchableString({ ...searchString })
          : ''),
        {},
        'GET'
      ).then((data) => {
        setLoading(false)
        if (data.errors) return message.error(getError(data))
        setData(data)

        if (!staticTotals) setStaticTotals(data)
        setTotals(data)
      })
    } else {
      setLoading(true)
      const url = reportingTask
        ? `/tasks/reporting/?page_size=${pageSize}&page=${page}`
        : `/legislative/reporting_groups/?page_size=${pageSize}&page=${page}`
      api(
        url +
        (searchString
          ? '&' + store.getSearchableString({ ...searchString })
          : ''),
        {},
        'GET'
      ).then((data) => {
        setLoading(false)
        if (data.errors) return message.error(getError(data))
        reportingTask ? setData(data) : setData(data)
        if (reportingTask) {
          !staticTotals && setStaticTotals(data)
          setTotals(data)
        }
      })
      !reportingTask &&
        api(
          `/legislative/reporting_totals/?${store.getSearchableString({
            ...searchString,
          })}`,
          {},
          'GET'
        ).then((data) => {
          if (data.errors) return message.error(getError(data))

          setStaticTotals(data)
          setTotals(data)
        })
    }
  }


  const getFile = () => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')

    fetch(
      `${process.env.REACT_APP_API_DEV
      }${isReportSource
        ? reportingTask ? `/report_source/tasks/reporting/export/` :
          '/report_source/reporting/export/'
        : reportingTask ? '/tasks/reporting_export/' :
          '/legislative/reporting_groups_xlsx/'}?${store.getSearchableString({
            ...searchString,
          })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', isReportSource
          ? reportingTask ? 'reporting_report_tasks.xlsx'
            : 'reporting.xlsx'
          : reportingTask ? 'reporting_compliance_tasks.xlsx'
            : 'reporting_groups.xlsx')
        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }

  const onSort = (sorter, page) => {
    setPage(page)
    const mutatedDirection = {
      ascend: '',
      descend: '-',
    }
    if (sorter.order) {
      setSorter(sorter)
      setSearchString({
        ...searchString,
        ordering: mutatedDirection[sorter.order] + sorter.columnKey,
      })
    } else {
      const obj = searchString || {}

      delete obj.ordering

      setSearchString({ ...searchString, ...obj })
      setSorter(sorter)
    }
  }

  const onSubmit = (values) => {
    setSearchString({ ...searchString, ...values })
    setPage(1)
  }

  const onFilter = (key) => {
    setActive(key === active ? null : key)
    if (key === active)
      return setSearchString({ ...searchString, status: null })
    setSearchString({ ...searchString, status: key })
  }

  useEffect(() => {
    getData()
  }, [searchString, page, reportingTask])

  useEffect(() => {
    setSearchString(null)
    setSorter(null)
  }, [reportingTask])


  const notExpandebleService = isReportSource
    ? false
    : reportingTask
      ? false
      : trackingMode === "compliance_source_ultra_lite"
        ? false
        : true

  const shouldDisplayLiteButtons = isReportSource
    ? (trackingMode !== "report_source")
      ? true
      : false
    : (trackingMode !== "compliance_source")
      ? true
      : false

  const allButtons = shouldDisplayLiteButtons
    ? liteButtons
    : reportingTask
      ? taskReportingButtons
      : buttons;

  return (
    <ReportingContainer>
      <TopLine onSubmit={onSubmit} totals={staticTotals} isReportSource={isReportSource} reportingTask={reportingTask} />
      <Grid>
        <div>
          <Header style={{ marginTop: 15 }}>
            <HeaderTitle>{isReportSource ? 'Reports Reporting' : 'Compliance Item Reporting'}</HeaderTitle>
          </Header>
          <div style={{ border: '1px solid #c4cacc', marginBottom: 50 }}>
            <TableStyled
              rowKey={'group_number'}
              tableLayout='fixed'
              columns={isReportSource
                ? reportingTask
                  ? columnsCoreTasksReporting({ sorter, store, isReportSource }).sort((a, b) => a.order - b.order)
                  : columnsReportsReporting({ sorter, store, isReportSource }).sort((a, b) => a.order - b.order)
                : reportingTask ? columnsCoreTasksReporting({ sorter, store, isReportSource }).sort((a, b) => a.order - b.order)
                  : trackingMode === 'compliance_source_ultra_lite'
                    ? ultraLiteCommon({ sorter, store }).sort((a, b) => a.order - b.order)
                    : trackingMode === 'compliance_source_lite'
                      ? columnsLite({ sorter, store }).sort((a, b) => a.order - b.order)
                      : columns({ sorter, store, }).sort((a, b) => a.order - b.order)
              }
              dataSource={isReportSource ? data?.results : reportingTask ? data?.results : data?.groups}
              onChange={(pagination, _, sorter) => {
                onSort(sorter, pagination.current)
                setPage(pagination.current)
              }}
              loading={loading}
              expandedRowKeys={expanded}
              expandable={notExpandebleService && {
                expandedRowRender: (record) => <ExpandItem data={record} />,
                rowExpandable: (record) => record.legislatives?.length > 1,
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.legislatives?.length > 1 ? (
                    expanded ? (
                      <div
                        style={{ width: 34, fontSize: 24 }}
                        onClick={(e) => {
                          onExpand(record, e)
                          setExpanded([])
                        }}
                      >
                        -
                      </div>
                    ) : (
                      <div
                        style={{ width: 34, fontSize: 24 }}
                        onClick={(e) => {
                          onExpand(record, e)
                          setExpanded([record.group_number])
                        }}
                      >
                        +
                      </div>
                    )
                  ) : (
                    ''
                  ),
              }}
              pagination={{
                position: ['bottomLeft'],
                pageSize,
                hideOnSinglePage: false,
                total: data?.count,
                current: page,
                showSizeChanger: false,
              }}
              size='small'
            />
            <ItemsShowing
              page={page}
              pageSize={pageSize}
              total={data?.count}
              text={isReportSource || reportingTask ? '' : 'Duplicate Reference Numbers Have Been Combined'}
              style={{
                textAlign: 'right',
                marginTop: -31,
                paddingRight: 10,
                width: 'fit-content',
                marginBottom: 10,
              }}
            />
          </div>
        </div>
        <div>
          {allButtons.map((item) => (
            <StyledButton
              key={item.key}
              onClick={() => totals[item.key] && onFilter(item.key)}
              active={active === item.key}
              disabled={!totals?.[item.key]}
              color={item.color}
            >
              <div style={{ textDecoration: 'underline' }}>{item.title}{' '}</div>
              <span style={{ color: item.color }}> {totals?.[item.key]}</span>
            </StyledButton>
          ))}
          {!reportingTask &&
            <ExportText>
              Includes All Expanded Items
            </ExportText>
          }
          <Button
            text='Export'
            onClick={getFile}
            style={{ width: '100%', marginTop: 15 }}
          />
          <ExportText>
            Will export all filtered <br /> results to a .xlsx
          </ExportText>
        </div>
      </Grid>
    </ReportingContainer>
  )
}

export default Reporting

const buttons = [
  {
    key: 'unassigned',
    title: 'Unassigned',
    color: 'var(--blue)',
  },
  {
    key: 'in_progress',
    title: 'In Progress',
    color: 'var(--green)',
  },
  {
    key: 'closed',
    title: 'Closed',
    color: '#969FA2',
  },
]

const liteButtons = [
  {
    key: 'unassigned',
    title: 'Tracking',
    color: 'var(--blue)',
  },
  {
    key: 'closed',
    title: 'Archived',
    color: '#969FA2',
  },
]
const taskReportingButtons = [
  {
    key: 'incomplete',
    title: 'In Progress',
    color: 'var(--green)',
  },
  {
    key: 'complete',
    title: 'Closed',
    color: '#969FA2',
  },
]

const columns = ({ sorter, store, }) =>
  columnsCommon({
    store,
    isComplianceItemReport: true,
    reference_number: {
      width: store.company?.[0]?.is_premium ? '20%' : '25%',
      order: 1,
      render: (text, record) => {
        if (record.legislatives?.length > 1) return <Render name={'reference_number_cell'}>{text}</Render>
        return (
          <Render name={'reference_number_cell'}>
            {text}{' '}
            <Link
              to={
                record.status === 'Closed'
                  ? `/tracking/closed?compliance_item=${record.legislative_ids[0]}`
                  : `/tracking/main?compliance_item=${record.legislative_ids[0]}`
              }
              style={{
                textDecoration: 'underline',
                color: 'var(--text)',
                marginLeft: 5,
              }}
              target='_blank'
            >
              View Details
            </Link>
          </Render>
        )
      },
    },
    jurisdiction: {
      render: (text, record) => {
        return <Render name={'jurisdictions_cell'}>{text}</Render>
      },
      sorter: false,
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 2,
    },
    status: {
      render: (text, record) => {
        return (
          <Render
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
            name={'status_cell'}
          >
            <Status bg={getStatusColor(record.legislatives[0].status, record.legislatives[0])}>
              <div />
            </Status>
            {text}
          </Render>
        )
      },
      sorter: false,
      width: '10%',
    },
    topics: {
      sorter: false,
      render: (text, record) => {
        return <Render name={'topics_cell'}>{text}</Render>
      },
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 3,
    },
    line_of_business: {
      sorter: false,
      render: (text, record) => {
        return <Render name={'lob_cell'}>{text}</Render>
      },
      width: '15%',
      order: 4,
    },
    adopted_date: {
      sorter: false,
      align: 'center',
      width: '15%',
      order: 5,
      render: (text, record) => {
        return <Render name={'adopted_date_cell'}>{getDate(record.legislatives[0].adopted_date) || 'See notes'}</Render>
      }
    },
    compliance_date: {
      sorter: false,
      align: 'center',
      width: '20%',
      order: 6,
      render: (text) => {
        return <Render name={'compliance_date_cell'}>{text || 'See notes'}</Render>
      },
    },
    is_starred: {
      sorter: false,
      width: '5%',
      order: 7,
    },
    lob: store.company?.[0]?.is_premium,
    sorter,
  })

const columnsLite = ({ sorter, store }) =>
  columnsCommonLite({
    store,
    reference_number: {
      width: store.company?.[0]?.is_premium ? '20%' : '25%',
      order: 1,
      render: (text, record) => {
        if (record.legislatives?.length > 1) return <Render name={'reference_number_cell'}>{text}</Render>
        return (
          <Render
            name={'reference_number_cell'}
          >
            {text}{' '}
            <Link
              to={
                record.status === 'Closed'
                  ? `/tracking/closed?compliance_item=${record.legislative_ids[0]}`
                  : `/tracking/main?compliance_item=${record.legislative_ids[0]}`
              }
              style={{
                textDecoration: 'underline',
                color: 'var(--text)',
                marginLeft: 5,
              }}
              target='_blank'
            >
              View Details
            </Link>
          </Render>
        )
      },
    },
    jurisdiction: {
      render: (text, record) => {
        return <Render name={'jurisdictions_cell'}>{text}</Render>
      },
      sorter: false,
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 2,
    },
    topics: {
      sorter: false,
      render: (text, record) => {
        return <Render name={'topics_cell'}>{text}</Render>
      },
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 3,
    },
    line_of_business: {
      sorter: false,
      render: (text, record) => {
        return <Render name={'lob_cell'}>{text}</Render>

      },
      width: '15%',
      order: 4,
    },
    compliance_date: {
      sorter: true,
      align: 'center',
      width: '20%',
      order: 6,
      render: (text) => {
        return <Render name={'compliance_date_cell'}>{text || 'See notes'}</Render>
      },
    },
    is_starred: {
      sorter: false,
      width: '5%',
      order: 7,
    },
    lob: store.company?.[0]?.is_premium,
    sorter,
  })

