import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Row, SubTitle } from '../HeldModal/style'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import { Controller } from 'react-hook-form'
import CalendarCustom from '../../../../components/common/Calendar'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import deleteFileIcon from '../../../../assets/images/delete-orange.svg'
import { useGA4React } from 'ga-4-react'
import moment from 'moment'
import { message, } from 'antd'

const RenewalCloseModal = ({
  onClose,
  id,
  currentPage,
  status,
  setTempStatus,
  licenseEffectiveDate,
  licenseRenewalDate,
  licenseNumber,
  licenseId,
  filingTimePeriod,
  mandatedExpirationDate,
  isPerpetual,
}) => {
  const store = useStore()
  const { control, errors, setValue, handleSubmit, register, watch, getValues } = useForm()
  const [temporaryFiles, setTemporaryFiles] = useState([])
  const ga4React = useGA4React()

  const postFile = (files) => {

    ga4React?.gtag('event', 'file_upload')

    let formData = new FormData()

    formData.append('file', files[0])

    api(`/license_company/${licenseId}/certificates/`, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
        message.success('File uploaded successfully')
      } else {
        message.error('File upload failed')
      }
    })
  }

  const addHeldDocs = (temporaryFiles) => {
    temporaryFiles.length > 0 &&
      temporaryFiles.map((item) => {
        return postFile(item)
      })
  }
  const onSubmit = (values) => {
    const url = `/renewals_reporting/${id}/close_renewal_license/`
    api(url, {
      status: status,
      renewal_date: moment(values.renewal_date).format('YYYY-MM-DD'),
      expiration_date: values.expiration_date ? moment(values.expiration_date).format('YYYY-MM-DD') : null,
      effective_date: moment(values.effective_date).format('YYYY-MM-DD'),
      closed_submitted_date: values.closed_submitted_date ? moment(values.closed_submitted_date).format('YYYY-MM-DD') : null,
      license_number: values.license_number,
    }, 'POST').then((data) => {
      if (!data.errors) {
        setTempStatus(null)
        addHeldDocs(temporaryFiles)
        store.setCurrentLegislative(data, true)
        store.loadLegislatives({ page: currentPage }, false, true)
        store.setSingleValue('newItemStatus', { id: id, status: status })
        onClose()
      }
    })
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files]])
  }

  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item[0].name !== name)
    setTemporaryFiles(filtered)
  }

  return (
    <ModalWrap onClose={onClose}>
      <Label>License Renewal Information</Label>
      <SubTitle>Filing Time Period: {filingTimePeriod}</SubTitle>
      {!isPerpetual && <SubTitle>State Mandated Expiration Date:{mandatedExpirationDate} </SubTitle>}
      <Row>
        <Controller
          control={control}
          name={'effective_date'}
          defaultValue={licenseEffectiveDate}
          render={({ onChange, value }) => (
            <CalendarCustom
              disabled={true}
              handleChange={() => { }}
              placeholder={'MM/DD/YYYY'}
              noLimit={true}
              incomeValue={licenseEffectiveDate}
              label={'License Effective Date'}
              error={errors?.date_license_obtained && 'This field is required'}
            />
          )}
        />
        <Controller
          control={control}
          name={'renewal_date'}
          rules={{ required: true }}
          render={({ onChange, value }) => (
            <CalendarCustom
              handleChange={(value) => {
                setValue('renewal_date', value)
              }}
              placeholder={'Select Date'}
              noLimit={true}
              required={true}
              label={'Filing Due Date'}
              incomeValue={value}
              error={errors?.renewal_date && 'This field is required'}
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          control={control}
          name={'expiration_date'}
          rules={{ required: true }}
          render={({ onChange, value }) => (
            <CalendarCustom
              handleChange={(value) => {
                setValue('expiration_date', value)
              }}
              noLimit={true}
              placeholder={'Select Date'}
              incomeValue={value}
              required={true}
              label={'State Mandated Expiration Date'}
              error={errors?.expiration_date && 'This field is required'}
            />
          )}
        />
        <Input
          style={{ marginBottom: 0 }}
          defaultValue={licenseNumber}
          smallLabel
          name={'license_number'}
          label={'License Number'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Enter Number'}
        />
      </Row>
      <Row>
        {status === 'closed_submitted' && (
          <>
            <Controller
              control={control}
              name={'closed_submitted_date'}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <CalendarCustom
                  handleChange={(value) => {
                    setValue('closed_submitted_date', value)
                  }}
                  noLimit={true}
                  placeholder={'Select Date'}
                  incomeValue={value}
                  required={true}
                  label={'Submitted Date'}
                  error={errors?.closed_submitted_date && 'This field is required'}
                />
              )}
            />
          </>
        )}
      </Row>
      <Input
        type={'file'}
        name={'file'}
        label={'Attach License Certificate(s):'}
        control={control}
        styleRow={{ marginTop: 10, marginBottom: 10 }}
        handleChange={(e) => addFile(e)}
        multiple
        smallLabel
      />
      {temporaryFiles.map((item, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonOrange text={item[0].name} key={index} />
          <img
            src={deleteFileIcon}
            alt={'delete-icon'}
            onClick={() => deleteFile(item[0].name)}
            style={{ marginLeft: 5, cursor: 'pointer' }}
          />
        </div>
      ))}

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          text={'Close Renewal Item'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </ModalWrap>
  )
}

export default RenewalCloseModal
