import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Text } from '../right.style'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input'
import { Select } from 'antd'
import { useStore } from '../../../../mobx-store/context'
import { checkRole } from '../../../../helpers/helpers'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'
import CalendarCustom from '../../../../components/common/Calendar'

const DeficiencyModal = ({
  editMode,
  defaultValue,
  type,
  onClose,
  onSubmit,
  showCancel = true
}) => {
  const [value, setValue] = useState(editMode ? defaultValue : '')


  return (
    <ModalWrap styleWrap={{ width: '25%' }} onClose={onClose} id={'deficiencyModal'}>
      <Label>{type === 'deficiency'|| type === 'ia_deficiency' ? ` ${editMode ? 'Edit' : 'Enter'} Deficiency Due Date` : `${editMode ? 'Edit' : 'Enter'} Date Submitted`}</Label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30, flexDirection: 'column' }}>
        <CalendarCustom
          handleChange={(value) =>
            setValue(value, 'internal_due_date')
          }
          noLimit={type === 'deficiency'|| type === 'ia_deficiency' ? false : true}
          incomeValue={value || ''}
          styleDropdown={{ left: 'auto', right: 0, top: 50 }}
          placeholder={'Select Date'}
          label={type === 'deficiency' || type === 'ia_deficiency' ? 'Enter Deficiency Due Date' : 'Enter Date Submitted'}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 30 }}>
          {showCancel && <Cancel onClick={onClose}>Cancel</Cancel>}
          <Button
            text={editMode ? 'Edit' : 'Close'}
            style={{ marginLeft: 'auto' }}
            onClick={() => onSubmit(value)}
            disabled={!value}
          />
        </div>
      </div>
    </ModalWrap>
  )
}

export default DeficiencyModal
