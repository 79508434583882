import React from 'react'
import Input from '../../../components/common/Input'
import Validation from '../../../components/common/Validation/validation'
import Activities from './activities'
import CommentContainer from './Comment/commentContainer'
import CommentForm from './CommentForm/commentForm'
import MyEditor from './Details'
import MyEditorOld from './Details/DetailsOld'
import Documents from './documents'
import { Block } from './right.style'
import ReportMaterials from './ReportMaterials'
import HeldMaterials from './Details/HeldMaterials'
import ReportTimePeriod from './ReportMaterials/ReportTimePeriod'
import EditReportMaterials from './EditReportMaterials'
import GetReady from './ReportMaterials/GetReady/GetReady'
import GetReadyLight from './ReportMaterials/GetReady/index'
import Considerations from './Considerations'
import ImpactedClients from './Considerations/TagInput'

export const showTabs = (obj) => {
  return {
    assessment: {
      render: () => <MyEditorOld
        reportId={obj.data?.report?.id}
        legislative={obj.data?.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting}
        type={'assessment'}
      />,
    },
    assessment_history: {
      render: () => <MyEditorOld
        reportId={obj.data?.report?.id}
        legislative={obj.data?.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting}
        disabled={true}
        getDataFromHistory={true}
        type={'assessment'}
      />,
    },
    details_pending: {
      render: () => <MyEditorOld
        reportId={obj.data?.report?.id}
        legislative={obj.data?.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting}
        isNeedHistory={obj.isNeedHistory}
        disabled={obj.isNeedHistory}
        type={'details'}
      />,
    },
    details: {
      render: () => <MyEditor
        reportId={obj.data?.report?.id}
        legislative={obj.data?.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        detailsState={obj.detailsState}
        handleDetailsState={obj.handleDetailsState}
        type={'details'}
      />
      ,
    },
    comments: {
      render: () => (
        <>
          <CommentForm
            reportId={obj.data?.report?.id}
            legislative={obj.data?.id}
            isReportSource={obj.isReportSource}
            isLicenseSource={obj.isLicenseSource}
            getDataFromHistory={true}
            disabled={obj.isNeedHistory}
            isNeedHistory={obj.isNeedHistory}
            isRenewalsReporting={obj.isRenewalsReporting} />

          <Block style={{ paddingBottom: '1.5em' }}>
            <CommentContainer
              reportId={obj.data?.report?.id}
              legislative={obj.data?.id}
              isReportSource={obj.isReportSource}
              isLicenseSource={obj.isLicenseSource}
              isNeedHistory={obj.isNeedHistory}
              isRenewalsReporting={obj.isRenewalsReporting} />
          </Block>
        </>
      ),
    },
    comments_history: {
      render: () => (
        <>
          <CommentForm
            reportId={obj.data?.report?.id}
            legislative={obj.data?.id}
            isReportSource={obj.isReportSource}
            isLicenseSource={obj.isLicenseSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            disabled={true}
          />

          <Block style={{ paddingBottom: '1.5em' }}>
            <CommentContainer
              reportId={obj.data?.report?.id}
              legislative={obj.data?.id}
              isReportSource={obj.isReportSource}
              isLicenseSource={obj.isLicenseSource}
              isRenewalsReporting={obj.isRenewalsReporting}
              getDataFromHistory={true} />
          </Block>
        </>
      ),
    },
    documents_working: {
      render: () => (
        <>
          {obj.isReportSource ?
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'working')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : obj.isLicenseSource && obj.data.status !== 'held' && !obj.isNeedHistory ?
              <Input
                type={'file'}
                control={obj.control}
                name={'file'}
                placeholder={''}
                handleChange={(e) => {
                  obj.postFile(e, null, null, false, 'working')
                }}
                loading={obj.loading.file}
                styleContainer={{ marginTop: '1em' }}
                label={'Upload Document'}
                labelStyle={obj.labelStyle}
                transparent
              />
              : !obj.isLicenseSource && <Input
                type={'file'}
                control={obj.control}
                name={'file'}
                placeholder={''}
                handleChange={(e) => {
                  obj.postFile(e)
                }}
                loading={obj.loading.file}
                styleContainer={{ marginTop: '1em' }}
                label={'Upload Document'}
                labelStyle={obj.labelStyle}
                transparent
              />
          }
          <Documents reportId={obj.data?.report?.id}
            legislative={obj.data.id}
            getTasks={obj.getTasks}
            isLicenseSource={obj.isLicenseSource}
            isReportSource={obj.isReportSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            status={'working'}
            isNeedHistory={obj.isNeedHistory}
            showDelete={obj.isReportSource ? true : obj.isLicenseSource && (obj.data.status === 'held' || obj.isNeedHistory) ? false : true}
            activeTab={obj.activeTab} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_submitted: {
      render: () => (
        <>
          {obj.isReportSource || (obj.isLicenseSource && obj.data.status !== 'held' && !obj.isNeedHistory) ?
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'submitted')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : !obj.isLicenseSource &&
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents
            reportId={obj.data?.report?.id}
            legislative={obj.data.id}
            getTasks={obj.getTasks}
            isReportSource={obj.isReportSource}
            isLicenseSource={obj.isLicenseSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            isNeedHistory={obj.isNeedHistory}
            showDelete={obj.isReportSource ? true : obj.isLicenseSource && (obj.data.status === 'held' || obj.isNeedHistory) ? false : true}
            status={'submitted'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_finalized: {
      render: () => (
        <>
          {obj.isReportSource || (obj.isLicenseSource && obj.data.status !== 'held' && !obj.isNeedHistory) ?
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'finalized')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : !obj.isLicenseSource &&
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents isLicenseSource={obj.isLicenseSource}
            reportId={obj.data?.report?.id}
            legislative={obj.data.id}
            getTasks={obj.getTasks}
            isReportSource={obj.isReportSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            isNeedHistory={obj.isNeedHistory}
            showDelete={obj.isReportSource ? true : obj.isLicenseSource && (obj.data.status === 'held' || obj.isNeedHistory) ? false : true}
            status={'finalized'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_deficiency: {
      render: () => (
        <>
          {obj.isReportSource || (obj.isLicenseSource && obj.data.status !== 'held' && !obj.isNeedHistory) ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'deficiency')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : !obj.isLicenseSource &&
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents isLicenseSource={obj.isLicenseSource}
            reportId={obj.data?.report?.id}
            legislative={obj.data.id}
            getTasks={obj.getTasks}
            isReportSource={obj.isReportSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            isNeedHistory={obj.isNeedHistory}
            showDelete={obj.isReportSource ? true : obj.isLicenseSource && (obj.data.status === 'held' || obj.isNeedHistory) ? false : true}
            status={'deficiency'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_other: {
      render: () => (
        <>
          {obj.isReportSource || (obj.isLicenseSource && obj.data.status !== 'held' && !obj.isNeedHistory) ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'other')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : !obj.isLicenseSource &&
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents
            reportId={obj.data?.report?.id}
            legislative={obj.data.id}
            getTasks={obj.getTasks}
            isReportSource={obj.isReportSource}
            isLicenseSource={obj.isLicenseSource}
            isRenewalsReporting={obj.isRenewalsReporting}
            isNeedHistory={obj.isNeedHistory}
            showDelete={obj.isReportSource ? true : obj.isLicenseSource && (obj.data.status === 'held' || obj.isNeedHistory) ? false : true}
            status={'other'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    considerations: {
      render: () => <Considerations legislative={obj.data.id}
        assignee={obj.data.assignee}
        status={obj.data.status}
        visible={obj.visible}
        handleClose={obj.handleClose}
        handleOpen={obj.handleOpen}
        assigneNew={obj.assigneNew}
        setAssigneNew={obj.setAssigneNew}
        getConsiderationData={obj.getConsiderationData}
        considerationData={obj.considerationData}
      />,
    },
    impacted_clients: {
      render: () => <ImpactedClients id={obj.data.id}
        isReportSource={false}
        isLicenseSource={true}
        isRenewalsReporting={false}
      />,
    },
    impacted_clients_history: {
      render: () => <ImpactedClients
        id={obj.data.id}
        initalLicenseId={obj.isRenewalsReporting ? obj.data.license_company_id : obj.data.id}
        isReportSource={false}
        isLicenseSource={true}
        isRenewalsReporting={false}
        disabled={true}
        getDataFromHistory={true}
      />,
    },
    activity: {
      render: () => <Activities
        reportId={obj.data?.report?.id}
        legislative={obj.data.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting} 
        isNeedHistory={false}
        isAssessmentHistory={false} 
        />,
    },
    assessment_activity: {
      render: () => <Activities
        reportId={obj.data?.report?.id}
        legislative={obj.data.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting} 
        isNeedHistory={false}
        isAssessmentHistory={true} 
        />,
    },

  }
}

export const showReportMaterialsTabs = ({ data, isMyTasks, receiveGetReadyData, handleOpen, getReadyData, setAssigneNew, showModeBlocks, isLicenseSource, determinationId }) => {
  return {
    getReady: {
      render: () => showModeBlocks
        ? <GetReady
          title={data?.checkboxes_title}
          assignee={data?.assignee}
          getReadyData={getReadyData}
          receiveGetReadyData={receiveGetReadyData}
          reportCompanyId={data.id}
          handleOpen={handleOpen}
          setAssigneNew={setAssigneNew}
          isLicenseSource={isLicenseSource}
          determinationId={determinationId}

        />
        : <GetReadyLight
          title={data?.checkboxes_title}
          assignee={data?.assignee}
          toDoData={data.checkboxes_data}
          receiveGetReadyData={receiveGetReadyData}
          reportCompanyId={data.id}
          isMyTasks={isMyTasks}
          isLicenseSource={isLicenseSource}
        />
    },
    reportPeriod: {
      render: () => <ReportTimePeriod isTips={false} isMyTasks={isMyTasks} />
    },
    forms: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'forms'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
      />
    },
    notes: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notes'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
      />
    },
    guidance: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'guidances'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
      />
    },
    tips: {
      render: () => <ReportTimePeriod isTips={true}
        isMyTasks={isMyTasks} />
    },
  }
}

export const showLsMaterialsTabs = ({ data, isMyTasks, receiveGetReadyData, handleOpen, getReadyData, setAssigneNew, showModeBlocks, isLicenseSource, determinationId, isNeedHistory }) => {
  return {
    forms_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'forms_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    forms_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'forms_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    getReady: {
      render: () => <GetReady
        title={data?.checkboxes_title}
        assignee={data?.assignee}
        getReadyData={getReadyData}
        receiveGetReadyData={receiveGetReadyData}
        reportCompanyId={data.id}
        handleOpen={handleOpen}
        setAssigneNew={setAssigneNew}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
        isNeedHistory={isNeedHistory}
      />
    },
    getReady_renewals: {
      render: () => <GetReady
        title={data?.checkboxes_title}
        assignee={data?.assignee}
        getReadyData={getReadyData}
        receiveGetReadyData={receiveGetReadyData}
        reportCompanyId={data.id}
        handleOpen={handleOpen}
        setAssigneNew={setAssigneNew}
        isLicenseSource={isLicenseSource}
        renewals={true}
        determinationId={determinationId}
      />
    },
    notes_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notes_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    notes_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notes_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    guidance_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'guidance_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    guidance_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'guidance_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    tips_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'tips_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    tips_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'tips_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    notice_of_change_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notice_of_change_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    notice_of_change_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notice_of_change_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    confidentiality_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'confidentiality_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    confidentiality_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'confidentiality_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    definitions: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'definitions'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    legal_citations_needed: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'legal_citations_needed'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    citations_renewals: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'citations_renewals'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    state_contact: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'state_contact'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    penalties: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'penalties'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    tips: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'tips'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
    legal_citations: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'legal_citations'}
        isMyTasks={isMyTasks}
        isLicenseSource={isLicenseSource}
        determinationId={determinationId}
      />
    },
  }
}
export const showAssessmentTabs = (obj) => {
  return {
    assessment: {
      render: () => <MyEditorOld
        reportId={obj.data?.report?.id}
        legislative={obj.data?.id}
        isReportSource={obj.isReportSource}
        isLicenseSource={obj.isLicenseSource}
        isRenewalsReporting={obj.isRenewalsReporting}
        type={'assessment'}
      />,
    },
  }
}

export const showHeldMaterialsTabs = ({ data, setVisibleHeldAddModal, setHeldType, trigger, setTrigger, determinationId, historyMode, heldHistory = {} }) => {
  return {
    admin_actions: {
      render: () => <HeldMaterials
        liceneCompanyId={data?.id}
        materialType={'admin_actions'}
        trigger={trigger}
        setTrigger={setTrigger}
        historyMode={historyMode}
        historyData={heldHistory}
        onAddNew={() => {
          setHeldType('admin_actions')
          setVisibleHeldAddModal(true)
        }}
      />
    },
    notice_of_change_held: {
      render: () => <HeldMaterials
        liceneCompanyId={data?.id}
        materialType={'notice_of_change_held'}
        trigger={trigger}
        historyMode={historyMode}
        historyData={heldHistory}
        setTrigger={setTrigger}
        determinationId={determinationId}
        onAddNew={() => {
          setHeldType('notice_of_change_held')
          setVisibleHeldAddModal(true)
        }}
      />
    },
    market_conduct_exams_held: {
      render: () => <HeldMaterials
        liceneCompanyId={data?.id}
        historyMode={historyMode}
        historyData={heldHistory}
        materialType={'market_conduct_exams_held'}
        trigger={trigger}
        setTrigger={setTrigger}
        determinationId={determinationId}
        onAddNew={() => {
          setHeldType('market_conduct_exams_held')
          setVisibleHeldAddModal(true)
        }}
      />
    },
    misc_held: {
      render: () => <HeldMaterials
        liceneCompanyId={data?.id}
        materialType={'misc_held'}
        historyMode={historyMode}
        historyData={heldHistory}
        determinationId={determinationId}
        trigger={trigger}
        setTrigger={setTrigger}
        onAddNew={() => {
          setHeldType('misc_held')
          setVisibleHeldAddModal(true)
        }}
      />
    },
  }
}

export const editReportMaterialsTabs = ({ control }) => {
  return {
    forms: {
      render: () => <EditReportMaterials control={control} materialType={'forms'} />
    },
    instructions: {
      render: () => <EditReportMaterials control={control} materialType={'instructions'} />
    },
    guidance: {
      render: () => <EditReportMaterials control={control} materialType={'guidances'} />
    },
    definitions: {
      render: () => <EditReportMaterials control={control} materialType={'definitions'} />
    },
  }
}


export const reportMaterialsTabs = [
  { key: 'getReady', title: 'Checklist', },
  { key: 'reportPeriod', title: 'Report Period' },
  { key: 'forms', title: 'Forms', },
  { key: 'guidance', title: 'Guidance', },
  { key: 'notes', title: 'Notes', },
  { key: 'tips', title: 'Tips', },
]

export const allLicenseMaterialsTabs = [
  { key: 'state_contact', title: 'State Contact' },
  { key: 'tips', title: 'Tips' },
  { key: 'penalties', title: 'Penalties' },
  { key: 'legal_citations', title: 'Citations' },
]

export const needmaterialsTabs = [
  { key: 'getReady', title: 'Checklist' },
  { key: 'forms_needed', title: 'Forms' },
  { key: 'guidance_needed', title: 'Guidance' },
  { key: 'notes_needed', title: 'Notes' },
  { key: 'tips_needed', title: 'Tips' },
  { key: 'legal_citations_needed', title: 'Citations' },
  { key: 'notice_of_change_needed', title: 'Notices of Change' },
  { key: 'confidentiality_needed', title: 'Confidentiality' },
]

export const renewalsTabs = [
  { key: 'getReady_renewals', title: 'Checklist' },
  { key: 'forms_renewals', title: 'Forms' },
  { key: 'guidance_renewals', title: 'Guidance' },
  { key: 'notes_renewals', title: 'Notes' },
  { key: 'tips_renewals', title: 'Tips' },
  { key: 'citations_renewals', title: 'Citations' },
  { key: 'notice_of_change_renewals', title: 'Notices of Change' },
  { key: 'confidentiality_renewals', title: 'Confidentiality' },
]

export const heldmaterialsTabs = [
  { key: 'getReady', title: 'Checklist' },
  { key: 'forms_needed', title: 'Forms' },
  { key: 'notes_needed', title: 'Notes' },
  { key: 'guidance_needed', title: 'Guidance' },
  { key: 'notice_of_change_needed', title: 'Notices of Change' },
  { key: 'tips_needed', title: 'Tips' },
  { key: 'legal_citations_needed', title: 'Citations' },
]

export const heldAdminActionsTabs = [
  { key: 'admin_actions', title: 'Admin Actions' },
  { key: 'market_conduct_exams_held', title: 'Market Conduct Exams' },
  { key: 'notice_of_change_held', title: 'Notices of Change' },
  { key: 'misc_held', title: 'Misc' },
]

export const getTabs = ({ isReportSource, isLicenseSource, isRenewalsReporting, data, isAssessment = false }) => {
  if (isAssessment) {
    return [
      { key: 'assessment', title: 'Assessment' },
    ];
  }
  if (isReportSource) {
    return [
      { key: 'details', title: 'Details' },
      { key: 'comments', title: 'Comments' },
      { key: 'documents_working', title: 'Working' },
      { key: 'documents_finalized', title: 'Finalized' },
      { key: 'documents_submitted', title: 'Submitted' },
      { key: 'documents_deficiency', title: 'Deficiency' },
      { key: 'documents_other', title: 'Other' },
      { key: 'activity', title: 'Activity' },
    ];
  }

  if (isLicenseSource) {
    if (data.status === 'not_assessed' || data.status === 'na' || data.status === 'monitor') {
      return [
        { key: 'assessment', title: 'Assessment' },
        { key: 'comments', title: 'Comments' },
        { key: 'activity', title: 'Activity' },
        { key: 'impacted_clients', title: 'Impacted Clients' },
      ];
    }
    return [
      { key: 'details_pending', title: 'Details' },
      { key: 'comments', title: 'Comments' },
      { key: 'documents_working', title: 'Working', },
      { key: 'documents_finalized', title: 'Finalized' },
      { key: 'documents_submitted', title: 'Submitted' },
      { key: 'documents_deficiency', title: 'Deficiency' },
      { key: 'documents_other', title: 'Other' },
      { key: 'activity', title: 'Activity' },
    ];
  }

  return [
    { key: 'details', title: 'Details' },
    { key: 'comments', title: 'Comments' },
    { key: 'documents_working', title: 'Documents' },
    { key: 'activity', title: 'Activity' },
    { key: 'considerations', title: 'Considerations' },
  ];
};